<template>
  <div :class="{ 'content-wrapper': !isTab }" class="talent-analysis-report">
    <b-button class="dont-print mb-4" @click="printPdf">Stampa</b-button>
    <div>
      <div v-show="!report_present" class="py-3">
        This user has not a Talent Analysis Report associated
      </div>
      <b-overlay
        :show="showOverlay && report_present"
        rounded="sm"
        class="overlay-placeholder"
      >
        <!-- <div v-show="!showOverlay">
        <div class="d-flex flex-column align-items-center mb-5" v-if="!idUser"> -->
        <div>
          <div
            class="d-flex flex-column align-items-center mb-5 do-print pt-5 sheet padding-10mm"
          >
            <h1>Analisi del talento</h1>
            <!-- Wrapper del grafico -->
            <div class="chart-wrapper" ref="chartdiv">
              <div
                v-if="user.avatar_url"
                class="photo-cicrle"
                :style="{ backgroundImage: 'url(' + user.avatar_url + ')' }"
              ></div>
              <div
                v-else
                class="
                photo-cicrle
                bg-transparent
                d-flex
                align-items-center
                justify-content-center
              "
              >
                <b-spinner></b-spinner>
              </div>
            </div>

            <h3>{{ (user.nome || "") + " " + (user.cognome || "") }}</h3>
          </div>

          <section
            v-for="(supergroup, i) in supergroups"
            :key="'data-supergroup-' + i"
            class="sheet padding-10mm do-print"
          >
            <div class="row supergroup">
              <div
                v-for="group in supergroup"
                :key="group.GroupName"
                class="mt-3 "
                :class="['col-' + 12 / supergroup.length, { 'px-3': !isTab }]"
              >
                <div style="height: 75px; display:flex; align-items: flex-end;">
                  <h4>{{ group.GroupName }}</h4>
                </div>
                <div
                  v-for="(item, i) in group.Data"
                  :key="'a' + i"
                  class="mb-2"
                >
                  <div class="text-label d-flex justify-content-between mb-2">
                    <span style="font-size:10px">{{ item.LabelSx }}</span>
                    <span style="font-size:10px">{{ item.LabelDx }}</span>
                  </div>
                  <b-progress max="9.75" class="mb-4">
                    <b-progress-bar
                      :value="item.Valore"
                      show-value
                      :style="{ backgroundColor: item.color }"
                    ></b-progress-bar>
                  </b-progress>
                </div>
              </div>
            </div>
          </section>

          <section class="sheet padding-10mm do-print ">
            <div class="mt-4 row-supergroup">
              <h4>Premessa</h4>
              <span v-if="item.Premessa">{{ item.Premessa }}</span>
            </div>
            <div
              class="row-supergroup"
              v-for="(group, i) in [item.TextGroup[0]]"
              :key="'text-supergroup-' + i"
            >
              <div>
                <h4 class="mt-5">
                  {{
                    group.GroupName.charAt(0).toUpperCase() +
                      group.GroupName.slice(1)
                  }}
                </h4>
                <div v-for="(column, i) in group.Columns" :key="'column-' + i">
                  <div v-if="column.Valori != null">
                    <div v-for="value in column.Valori" :key="value.ID">
                      <div v-if="value.attivo">
                        <strong>
                          <p class="mt-3">
                            {{
                              value.chiave.charAt(0).toUpperCase() +
                                value.chiave.slice(1)
                            }}
                          </p>
                        </strong>
                        {{
                          value.testo.charAt(0).toUpperCase() +
                            value.testo.slice(1)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="sheet padding-10mm do-print">
            <div
              class="row-supergroup"
              v-for="(group, i) in [item.TextGroup[1]]"
              :key="'text-supergroup-' + i"
            >
              <div>
                <h4 class="mt-5">
                  {{
                    group.GroupName.charAt(0).toUpperCase() +
                      group.GroupName.slice(1)
                  }}
                </h4>
                <div v-for="(column, i) in group.Columns" :key="'column-' + i">
                  <div v-if="column.Valori != null">
                    <div v-for="value in column.Valori" :key="value.ID">
                      <div v-if="value.attivo">
                        <strong>
                          <p class="mt-3">
                            {{
                              value.chiave.charAt(0).toUpperCase() +
                                value.chiave.slice(1)
                            }}
                          </p>
                        </strong>
                        {{
                          value.testo.charAt(0).toUpperCase() +
                            value.testo.slice(1)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="sheet padding-10mm do-print">
            <div
              class="row-supergroup"
              v-for="(group, i) in [item.TextGroup[2]]"
              :key="'text-supergroup-' + i"
            >
              <div>
                <h4 class="mt-5">
                  {{
                    group.GroupName.charAt(0).toUpperCase() +
                      group.GroupName.slice(1)
                  }}
                </h4>
                <div v-for="(column, i) in group.Columns" :key="'column-' + i">
                  <div v-if="column.Valori != null">
                    <div v-for="value in column.Valori" :key="value.ID">
                      <div v-if="value.attivo">
                        <strong>
                          <p class="mt-3">
                            {{
                              value.chiave.charAt(0).toUpperCase() +
                                value.chiave.slice(1)
                            }}
                          </p>
                        </strong>
                        {{
                          value.testo.charAt(0).toUpperCase() +
                            value.testo.slice(1)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 row-supergroup mb-5">
              <h4>Conclusioni</h4>
              <span v-if="item.Conclusioni"> {{ item.Conclusioni }} </span>
            </div>
          </section>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService"
import { hslToHex } from "@/utils/hslToRgb.js"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import * as am5radar from "@amcharts/amcharts5/radar"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
export default {
  components: {},
  props: {
    idUser: Number,
    isTab: {
      value: Boolean,
      default: false,
    },
    showFlower: {
      value: Boolean,
      default: true,
    },
    showCheckboxes: {
      value: Boolean,
      default: true,
    },
  },

  // ["idUser", "isTab", "showFlower"],
  data() {
    return {
      idReport: this.$route.params.reportId,
      item: {},
      showOverlay: true,
      root: null,

      user: {},

      currentPage: 1,
      perPage: 10,
      filter: "",

      hueList: {
        AVERE: 207,
        ESSERE: 342,
        FARE: 130,
        "INDICI DELLO STILE DI RISPOSTA": 250,
        Orientamento: 250,
        "Ruolo nel Gruppo": 250,
        "SCALE DERIVATE SPECIALI": 250,
        "Stile Collaborazione": 190,
        "Stile di Influenzamento": 190,
        "Stile Leadership": 190,
      },

      report_present: true,
      stato_options: [
        { text: "Non pubblicato", value: 2 },
        { text: "Pubblicato", value: 3 },
      ],
    }
  },
  service: null,
  userService: null,
  created() {
    this.service = new AxiosService("AnalisiDelTalento")
    this.userService = new AxiosService("User")
  },
  mounted() {
    this.$nextTick(() => {
      console.log("CONTROLLO ID", this.idUser)
      if (this.idUser) {
        this.service
          .readCustomEndpoint("AnalisiDelTalentoByUser", this.idUser)
          .then((data) => {
            if (data) {
              this.item = data
              this.userService.readById(this.idUser).then((data) => {
                this.user = data
                this.preset()
              })
              this.report_present = true
            } else {
              this.report_present = false
            }
          })
          .catch(() => {
            this.report_present = false
            this.showOverlay = false
          })
      } else {
        this.service.readById(this.idReport).then((data) => {
          this.item = data
          this.userService.readById(this.item.ID_User).then((data) => {
            this.user = data
            this.preset()
          })
        })
      }
    })
  },
  methods: {
    printPdf() {
      window.print()
    },
    preset() {
      this.item.DataGroup.forEach((element) => {
        let hue = null
        if (element.GroupName) {
          hue = this.hueList[element.GroupName]
          if (
            typeof hue !== "undefined" &&
            element.Data &&
            Array.isArray(element.Data)
          ) {
            element.Data = this.associateColors(element.Data, hue).slice()
          }
        }
      })
      this.showOverlay = false
      this.setChart()
    },
    getCompanyName(id) {
      const item = this.companyList.find((x) => x.value === id)
      return item ? item.text : ""
    },

    saveItem() {
      if (this.item && this.item.ID) {
        this.service
          .putCustomEndpoint(
            "AnalisiDelTalentoSetText",
            this.item.ID,
            this.item
          )
          .then((response) => {
            if (response.ID == this.item.ID) {
              this.$successToast("Report saved")
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
      } else {
        this.$errorToast()
        return false
      }
    },

    associateColors(list, hue) {
      let saturation = 100
      const startLightness = 30
      const endLightness = 85
      const range = endLightness - startLightness
      const step = Math.floor(range / list.length - 1)
      const stepColor = 4
      let currentLightness = startLightness
      hue = hue - Math.floor((stepColor * list.length) / 2)
      hue = hue < 0 ? 360 + hue : hue
      list.forEach((element) => {
        element.color = hslToHex(hue, saturation, currentLightness)
        currentLightness += step
        hue = (hue + stepColor) % 360
      })
      return list
    },

    setChart() {
      if (!this.showFlower) return false
      let root = am5.Root.new(this.$refs.chartdiv)
      root.setThemes([am5themes_Animated.new(root)])

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          startAngle: -90,
          endAngle: 270,
          //raggio esterno e raggio interno
          radius: 165,
          innerRadius: 85,
        })
      )

      // Add scrollbar
      // // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      // chart.set(
      //   "scrollbarX",
      //   am5.Scrollbar.new(root, {
      //     orientation: "horizontal",
      //     exportable: false,
      //   })
      // )

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 30,
      })

      xRenderer.grid.template.set("forceHidden", true)
      xRenderer.labels.template.set("forceHidden", true)

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "category",
          renderer: xRenderer,
        })
      )

      var yRenderer = am5radar.AxisRendererRadial.new(root, {})
      yRenderer.grid.template.set("forceHidden", true)
      yRenderer.labels.template.set("forceHidden", true)

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          //maxDeviation: 0.3,
          min: 0,
          max: 10,
          renderer: yRenderer,
        })
      )

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          name: "Series 1",
          sequencedInterpolation: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "category",
        })
      )

      // Rounded corners for columns
      series.columns.template.setAll({
        cornerRadius: 5,
        tooltipText: "{categoryX}: {valueY}",
        templateField: "columnSettings",
      })

      xAxis.data.setAll(this.chartData)
      series.data.setAll(this.chartData)

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000)
      chart.appear(1000, 100)

      this.root = root
    },
    setSuggerimenti(group, col, key, state) {
      if (group == "aree di miglioramento rispetto al ruolo") {
        const suggerimenti = this.item.TextGroup.find((obj) => {
          return obj.GroupName === "Suggerimenti per il miglioramento personale"
        })
        let valore = suggerimenti.Columns[col].Valori.find((obj) => {
          return obj.chiave === key
        })
        valore.attivo = state
      }
    },
  },
  computed: {
    supergroups() {
      let supergroupsArray = [[], [], []]
      if (this.item.DataGroup && Array.isArray(this.item.DataGroup)) {
        this.item.DataGroup.forEach((element) => {
          const nome = element.GroupName
          if (nome == "AVERE" || nome == "ESSERE" || nome == "FARE") {
            supergroupsArray[0].push(element)
          } else if (nome.startsWith("Stile")) {
            supergroupsArray[2].push(element)
          } else {
            supergroupsArray[1].push(element)
          }
        })
      }
      return supergroupsArray
    },
    chartData() {
      //variabile dei dati del grafico
      let data = []
      this.item.DataGroup.forEach((element) => {
        const nome = element.GroupName

        if (nome == "AVERE" || nome == "ESSERE" || nome == "FARE") {
          element.Data.forEach((x) => {
            let current = {}
            current.category = x.LabelSx + " - " + x.LabelDx
            current.value = x.Valore
            current.columnSettings = {
              fill: x.color,
              stroke: x.color,
            }
            data.push(current)
          })
        }
      })
      return data
    },
  },
}
</script>

<style scoped>
.e {
  border: 1px solid red;
}
.do-print {
  width: 230mm;
  height: 296mm;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

.sheet.padding-10mm {
  padding: 100px 10mm;
}

.talent-analysis-report {
  .progress {
    background-color: #e6e6e6 !important;
    border-radius: 8px !important;
    text-shadow: 1px 1px 1px #0005;
    font-weight: bold;
  }

  .photo-cicrle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #888;
    background-size: cover;
    background-position: center;
    position: absolute;
    /*offset verticale da trovare sperimentando in base all'altezza del wrapper */
    top: 170px;
    left: 50%;
    transform: translateX(-50%);
  }

  .supergroup {
    margin-bottom: 5rem;
  }

  .text-label {
    font-size: 15px;
  }

  .chart-wrapper {
    width: calc(100%);
    /*altezza del wrapper chart*/
    height: 500px;
    position: relative;
  }
}

@media print {
  .talent-analysis-page {
    .talent-list-button {
      width: 5.5rem;
    }
  }

  .dont-print {
    display: none;
  }
}
</style>
